import Head from "next/head";
import { NextPage } from "next";
import MarketsOverview from "@/components/markets-overview/MarketsOverview";

const HomePage: NextPage = (props) => {
    return (
        <>
            <Head>
                <title>ORTEX - Global Financial Analytics</title>
                <link rel="canonical" href="https://app.ortex.com/" />
                <meta
                    name="keywords"
                    content="Stocks, Financial analytics, financial data, stock screening, short interest, short squeeze, options, options flow, gamma squeeze, fundamentals, stocks, NYSE, Nasdaq, equity, trading, trading ideas, trading signals, dividend"
                />

                <meta name="description" content="ORTEX - Global Financial Analytics with live short interest, options, analyst data and much more" />
                <meta name="twitter:title" content="ORTEX - Global Financial Analytics" />
                <meta name="twitter:description" content="ORTEX - Global Financial Analytics with live short interest, options, analyst data and much more" />
                <meta name="twitter:url" content="https://app.ortex.com/" />
                <meta property="og:title" content="ORTEX - Global Financial Analytics" />
                <meta property="og:description" content="ORTEX - Global Financial Analytics with live short interest, options, analyst data and much more" />
                <meta property="og:url" content="https://app.ortex.com/" />
            </Head>
            <MarketsOverview />
        </>
    );
};

export default HomePage;
